import React from "react";

const Footer = () => {
  return (
    <>
      {/* <!-- footer area start --> */}
      <footer>
        <div className="tp-footer__area white-bg">
          <div className="tp-footer">
            {/* <!-- main-footer start  --> */}
            <div className="tp-footer__main">
              <div className="container">
                <div className="tp-footer-border pt-60 pb-30">
                  <div className="row text-center text-md-start">
                    <div className="col-lg-3 col-md-6">
                      <div className="tp-footer__widget pb-30">
                        <img
                          src={"assets/img/logo/logoblue.png"}
                          alt="logo"
                          className="pb-4 mx-auto d-block d-md-inline"
                        />
                        <ul className="list-unstyled">
                          <li>
                            <span
                              style={{ color: "#1A224C", lineHeight: "8px" }}
                            >
                              Calle de Chile, 8, Oficina 209,
                              <br /> Las Rozas de Madrid, España
                            </span>
                          </li>
                          <li>
                            <span style={{ color: "#1A224C" }}>
                              +91 977 05 99
                            </span>
                          </li>
                          <li>
                            <span style={{ color: "#1A224C" }}>
                              info@thinkia.com
                            </span>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-6"></div>
                    <div className="col-lg-3 col-md-6 "></div>
                    <div className="col-lg-3 col-md-6">
                      <div className="tp-footer__widget pt-30 justify-content-center justify-content-md-end text-center text-md-end">
                        <ul className="list-unstyled">
                          <li>
                            <a href="#inicio" style={{ color: "#1A224C" }}>
                              Inicio
                            </a>
                          </li>
                          <li>
                            <a
                              href="#quienes-somos"
                              style={{ color: "#1A224C" }}
                            >
                              Quiénes somos
                            </a>
                          </li>
                          <li>
                            <a href="#que-hacemos" style={{ color: "#1A224C" }}>
                              Qué hacemos
                            </a>
                          </li>
                          <li>
                            <a href="#thinkia-training" style={{ color: "#1A224C" }}>
                              Thinkia Training
                            </a>
                          </li>
                          <li>
                            <a href="#contacto">
                              <span
                                style={{ color: "#00AFFF", fontWeight: 600 }}
                              >
                                Contacto
                              </span>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <!-- main footer end  --> */}
          </div>
        </div>

        {/* <!-- footer copy right --> */}
        <div className="top-footer-copyright pt-30 white-bg">
          <div className="container">
            <div
              className="mb-4"
              style={{ backgroundColor: "#1A224C", height: "1px" }}
            ></div>
            <div className="row text-center text-md-start">
              <div className="col-md-8 col-12">
                <div className="tp-copyrigh-text mb-30">
                  <span style={{ color: "#1A224C" }}>
                    Política de Privacidad | Política de Cookies | Aviso Legal
                  </span>
                </div>
              </div>
              <div className="col-md-4 col-12">
                <div className="tp-footer-social-icon mb-30 text-center text-md-end">
                  <ul className="list-unstyled d-flex justify-content-center justify-content-md-end">
                    <li>
                      <i
                        style={{ color: "#1A224C" }}
                        className="fab fa-linkedin"
                      ></i>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- footer copyright end  --> */}
      </footer>
      {/* <!-- footer area end --> */}
    </>
  );
};

export default Footer;
