import React from "react";

const Menus = () => {
  return (
    <ul className="d-flex list-unstyled d-md-flex d-none">
      <li className="text-white fw-light" style={{ fontFamily: "Poppins", fontSize: '16px',padding: 30 }}>
        <a className="link-nav-thinkia" href="#inicio">Inicio</a>
      </li>
      <li className="text-white fw-light" style={{ fontFamily: "Poppins", fontSize: '16px',padding: 30 }}>
        <a className="link-nav-thinkia"  href="#quienes-somos">Quiénes somos</a>
      </li>
      <li className="text-white fw-light" style={{ fontFamily: "Poppins", fontSize: '16px',padding: 30 }}>
        <a className="link-nav-thinkia"  href="#que-hacemos">Qué hacemos</a>
      </li>
      <li className="text-white fw-light" style={{ fontFamily: "Poppins", fontSize: '16px',padding: 30 }}>
        <a className="link-nav-thinkia"  href="#thinkia-training">Thinkia Training</a>
      </li>
    </ul>
  );
};

export default Menus;