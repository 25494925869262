import React from "react";
import { Link } from "react-router-dom";
import Menus from "./Menus";
import "../../../assets/css/menu.css";

const Menu = () => {
  return (
    <header>
      <div
        id="header-sticky"
        className="header__area header__transparent pr-90 pl-90 header-sticky-active header-sticky col-12"
        style={{ backgroundColor: "#07081C" }}
      >
        <div className="header__main bg-header" id="header-sticky">
          <div className="container-fluid">
            <div className="row align-items-center">
              <div className="col-lg-2 col-md-4 col-4">
                <div className="logo">
                  <Link to="/">
                    <img src={"assets/img/logo/logo.svg"} alt="logo" />
                  </Link>
                </div>
              </div>
              <div className="col-lg-8 d-none d-lg-block">
                <div className="header__main_right d-flex justify-content-end align-items-center">
                  <Menus />
                </div>
              </div>
              <div className="col-lg-2 col-md-4 col-4 d-flex justify-content-end align-items-center">
                <a href="#contacto" className="d-none d-md-block">
                  <button
                    className="contact-button rounded-pill text-white py-2 px-4 fw-light"
                    style={{ backgroundColor: "#00AFFF" }}
                  >
                    Contacto
                  </button>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Menu;
