import AppNavigation from "./app-navigation/AppNavigation";
import 'bootstrap/dist/css/bootstrap.min.css'; // Importar estilos de Bootstrap
import 'bootstrap/dist/js/bootstrap.bundle.min.js'; // Importar JavaScript de Bootstrap


function App() {
  return <AppNavigation />;
}

export default App;
